// ! automatically generated - Don't change this file manually
import PropTypes from 'prop-types';
import React from 'react';
const ICON_SIZE = { small: '1rem', medium: '1.25rem', large: '1.5rem' };;
export const IconNavigation = React.forwardRef(({
  title,
  titleId: titleIdProp,
  desc,
  descId: descIdProp,
  size,
  height: heightProp,
  width: widthProp,
  ...props
}, ref) => {
  const internalTitleId = React.useId();
  const internalDescId = React.useId();
  const titleId = title != null ? titleIdProp ?? internalTitleId : undefined;
  const descId = desc != null ? descIdProp ?? internalDescId : undefined;
  const height = ICON_SIZE[size] ?? size ?? heightProp ?? "1em";
  const width = ICON_SIZE[size] ?? size ?? widthProp ?? "1em";
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}  viewBox="0 0 24 24" width={width} height={height} role={title == null ? 'presentation' : 'img'} data-icon-name="navigation" ref={ref} aria-labelledby={titleId} aria-describedby={descId} {...props}>{desc ? <desc id={descId}>{desc}</desc> : null}{title ? <title id={titleId}>{title}</title> : null}<polygon points="3 11 22 2 13 21 11 13 3 11" /></svg>;
});
IconNavigation.displayName = "IconNavigation";
IconNavigation.propTypes = {
  /**
   * Icon title
   */
  title: PropTypes.string,
  /**
   * Icon title ID
   */
  titleId: PropTypes.string,
  /**
   * Icon description
   */
  desc: PropTypes.string,
  /**
   * Icon description ID
   */
  descId: PropTypes.string,
  /**
   * Size of the icon, these values are based on 'rem'
   * to make it adjustable to the user browser configuration.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['small', 'medium', 'large'])])
};