// ! automatically generated - Don't change this file manually
import PropTypes from 'prop-types';
import React from 'react';
const ICON_SIZE = { small: '1rem', medium: '1.25rem', large: '1.5rem' };;
export const Icon3Dots = React.forwardRef(({
  title,
  titleId: titleIdProp,
  desc,
  descId: descIdProp,
  size,
  height: heightProp,
  width: widthProp,
  ...props
}, ref) => {
  const internalTitleId = React.useId();
  const internalDescId = React.useId();
  const titleId = title != null ? titleIdProp ?? internalTitleId : undefined;
  const descId = desc != null ? descIdProp ?? internalDescId : undefined;
  const height = ICON_SIZE[size] ?? size ?? heightProp ?? "1em";
  const width = ICON_SIZE[size] ?? size ?? widthProp ?? "1em";
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 16" width={width} height={height} role={title == null ? 'presentation' : 'img'} data-icon-name="3-dots" ref={ref} aria-labelledby={titleId} aria-describedby={descId} {...props}>{desc ? <desc id={descId}>{desc}</desc> : null}{title ? <title id={titleId}>{title}</title> : null}<style>{"@keyframes buttonLoadingAnim{0%{transform:scale(1);animation-timing-function:cubic-bezier(.5,0,.5,1)}25%{transform:scale(1.5);animation-timing-function:cubic-bezier(.5,0,.5,1)}50%,to{transform:scale(1)}}"}</style><circle cx={9} cy={8} r={4} fill="currentColor" style={{
      transformOrigin: "18% 50%",
      animationName: "buttonLoadingAnim",
      animationDuration: "1.5s",
      animationIterationCount: "infinite"
    }} /><circle cx={25} cy={8} r={4} fill="currentColor" style={{
      transformOrigin: "50%center",
      animationName: "buttonLoadingAnim",
      animationDuration: "1.5s",
      animationIterationCount: "infinite",
      animationDelay: ".2s"
    }} /><circle cx={41} cy={8} r={4} fill="currentColor" style={{
      transformOrigin: "82% 50%",
      animationName: "buttonLoadingAnim",
      animationDuration: "1.5s",
      animationIterationCount: "infinite",
      animationDelay: ".4s"
    }} /></svg>;
});
Icon3Dots.displayName = "Icon3Dots";
Icon3Dots.propTypes = {
  /**
   * Icon title
   */
  title: PropTypes.string,
  /**
   * Icon title ID
   */
  titleId: PropTypes.string,
  /**
   * Icon description
   */
  desc: PropTypes.string,
  /**
   * Icon description ID
   */
  descId: PropTypes.string,
  /**
   * Size of the icon, these values are based on 'rem'
   * to make it adjustable to the user browser configuration.
   */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['small', 'medium', 'large'])])
};