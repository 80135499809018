import { Wrapper } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';

/**
 * Component to load the Google Maps api with all of the libraries needed for the site.
 * This component accepts all of the same props as @googlemaps/react-wrapper Wrapper component
 */
const GoogleMapsLoader = ({ apiKey, children, ...props }) => (
    <Wrapper
        {...props}
        apiKey={apiKey === 'STORYBOOK_GOOGLE_MAPS_API_KEY' ? '' : apiKey}
        id="GoogleMapsLoader"
        libraries={['places']}
        version="beta"
    >
        {children}
    </Wrapper>
);

GoogleMapsLoader.propTypes = {
    /**
     * Google Maps API key
     */
    apiKey: PropTypes.string,
    /**
     * Children to display
     */
    children: PropTypes.node,
};

export default GoogleMapsLoader;
