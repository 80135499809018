import React from 'react';

import { useLocalStorageGeolocation } from '@/hooks/useLocalStorageGeolocation';
import { googleMapsGeocode } from '@/libs/googleMaps/geocode';
import { arrayNotEmpty } from '@/utils/arrayNotEmpty';
import { getCityFromAddressComponents, getZipcodeFromAddressComponents } from '@/utils/googleMap';

const GeolocationApiContext = React.createContext();
const GeolocationStateContext = React.createContext();
const GeolocationStateIsLoadingContext = React.createContext();

export const GeolocationProvider = ({ children }) => {
    const [state, setState] = useLocalStorageGeolocation();
    const currentSetStateRef = React.useRef(setState);
    React.useEffect(() => {
        currentSetStateRef.current = setState;
    }, [setState]);
    const currentStateRef = React.useRef(state);
    React.useEffect(() => {
        currentStateRef.current = state;
    }, [state]);

    const [geolocationIsLoading, setGeolocationIsLoading] = React.useState(false);

    const getZipCodeFromBrowserGeolocationPosition = React.useCallback(async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const isDebugNavigatorGeolocation = searchParams.has('debug-navigator-geolocation');

        setGeolocationIsLoading(true);
        let currentPosition = null;

        if (!isDebugNavigatorGeolocation) {
            currentPosition = await new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                    },
                    (...args) => {
                        console.error(...args);
                        resolve(null);
                    },
                );
            });
        } else {
            currentPosition = {
                lat: 41.0895249,
                lng: -73.8419063,
            };
        }

        if (!currentPosition) {
            console.error('Browser Geolocation not allowed');

            setGeolocationIsLoading(false);
            return null;
        }

        if (
            currentStateRef.current?.isBrowserGeolocation === true &&
            currentPosition.lat === currentStateRef.current?.lat &&
            currentPosition.lng === currentStateRef.current?.lng
        ) {
            return currentStateRef.current;
        }

        const response = await googleMapsGeocode({ location: currentPosition });

        setGeolocationIsLoading(false);

        if (!arrayNotEmpty(response?.results)) {
            return null;
        }

        return {
            zipCode: getZipcodeFromAddressComponents(response),
            city: getCityFromAddressComponents(response.results[0]),
            ...currentPosition,
            isBrowserGeolocation: true,
        };
    }, []);

    const getGeolocationPositionFromZipCode = React.useCallback(async (arg) => {
        if (
            currentStateRef.current?.isBrowserGeolocation === false &&
            (arg === currentStateRef.current?.zipCode || arg === currentStateRef.current?.city)
        ) {
            return currentStateRef.current;
        }

        setGeolocationIsLoading(true);
        const response = await googleMapsGeocode({ address: arg });
        setGeolocationIsLoading(false);

        if (!arrayNotEmpty(response?.results)) {
            return null;
        }

        const currentPosition = {
            lat: response.results[0].geometry?.location?.lat(),
            lng: response.results[0].geometry?.location?.lng(),
        };

        return {
            zipCode: !isNaN(arg) ? arg : getZipcodeFromAddressComponents(response),
            city: isNaN(arg) ? arg : getCityFromAddressComponents(response),
            ...currentPosition,
            isBrowserGeolocation: false,
            recentSearch: arg,
        };
    }, []);

    const setGeolocation = React.useCallback(
        async (arg) => {
            if (arg == null) {
                currentSetStateRef.current(null);
                return;
            }

            const { zipCode: zipCodeArg, city: cityArg, browserGeolocation = false } = arg;

            let newState = null;

            try {
                if (zipCodeArg) {
                    newState = await getGeolocationPositionFromZipCode(zipCodeArg);
                } else if (cityArg) {
                    newState = await getGeolocationPositionFromZipCode(cityArg);
                } else if (browserGeolocation) {
                    newState = await getZipCodeFromBrowserGeolocationPosition();
                }
            } catch (e) {
                console.error(e);
            }

            currentSetStateRef.current(newState);
            return newState;
        },
        [getGeolocationPositionFromZipCode, getZipCodeFromBrowserGeolocationPosition],
    );

    const api = React.useMemo(
        () => ({
            getZipCodeFromBrowserGeolocationPosition,
            getGeolocationPositionFromZipCode,
            setGeolocation,
        }),
        [
            getGeolocationPositionFromZipCode,
            getZipCodeFromBrowserGeolocationPosition,
            setGeolocation,
        ],
    );

    return (
        <GeolocationApiContext.Provider value={api}>
            <GeolocationStateIsLoadingContext.Provider value={geolocationIsLoading}>
                <GeolocationStateContext.Provider value={state}>
                    {children}
                </GeolocationStateContext.Provider>
            </GeolocationStateIsLoadingContext.Provider>
        </GeolocationApiContext.Provider>
    );
};

export const useGeolocationApi = () => React.useContext(GeolocationApiContext);
export const useGeolocationState = () => React.useContext(GeolocationStateContext);
export const useGeolocationStateIsLoading = () =>
    React.useContext(GeolocationStateIsLoadingContext);

export const useHandleGeolocationButtonClick = (callback) => {
    const currentCallback = React.useRef(callback);
    React.useEffect(() => {
        currentCallback.current = callback;
    });

    const { setGeolocation } = useGeolocationApi();
    const state = useGeolocationState();
    const currentStateRef = React.useRef(state);
    React.useEffect(() => {
        currentStateRef.current = state;
    }, [state]);

    const handle = React.useCallback(
        async (e) => {
            e?.preventDefault?.();

            if (currentStateRef.current?.isBrowserGeolocation === true) {
                setGeolocation();
                currentCallback.current?.('');
                return;
            }

            const r = await setGeolocation({ browserGeolocation: true });

            if (r) {
                currentCallback.current?.(r.zipCode || r.city);
            } else {
                currentCallback.current?.('');
            }
        },
        [setGeolocation],
    );

    return handle;
};
