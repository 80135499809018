import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import {
    GeolocationProvider,
    useGeolocationState,
    useGeolocationStateIsLoading,
    useHandleGeolocationButtonClick,
} from '@/contexts/GeolocationContext';
import { TextField } from '@/design-system/atoms/Forms/TextField';
import { Typeahead } from '@/design-system/atoms/Forms/Typeahead';
import { Icon3Dots } from '@/design-system/atoms/Icons/Icon3Dots';
import { IconNavigation } from '@/design-system/atoms/Icons/IconNavigation';
import { IconXCircle } from '@/design-system/atoms/Icons/IconXCircle';
import { GoogleMapsLoader } from '@/design-system/helpers/GoogleMapsLoader';
import { googlePlacesAutocomplete } from '@/libs/googleMaps/places';

import styles from './PlacesTypeahead.module.scss';

export const PlacesAutocomplete = forwardRef((props, ref) => {
    const {
        label = 'City or Zip',
        variant = TextField.VARIANT.BOTTOM_BORDER,
        buttonProps,
        enableGeolocation = false,
        inputNameTypeahead,
        isInvalid,
        validationErrors,
        inputValue,
        defaultInputValue,
        onInputChange,
        onSelectionItemChange,
        onGeolocationSuccess,
        className,
        autoSelect,
        onBlur,
    } = props;
    const AUTOCOMPLETE_MIN_CHARACTERS = 3;

    const geolocationIsLoading = useGeolocationStateIsLoading();
    const geolocationState = useGeolocationState();
    const [suggestions, setSuggestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleGeolocationButton = useHandleGeolocationButtonClick(onGeolocationSuccess);

    const sessionToken = useMemo(
        () => new window.google.maps.places.AutocompleteSessionToken(),
        [],
    );

    const handleInputChange = useCallback(
        (value) => {
            if (autoSelect?.enabled) {
                onInputChange?.(value, suggestions);
            } else {
                onInputChange?.(value);
            }
        },
        [suggestions, onInputChange, autoSelect],
    );

    const handleBlur = useCallback(
        (e) => {
            if (autoSelect?.enabled && suggestions[0]?.text) {
                autoSelect?.callback?.(e, suggestions[0]?.text);
            }
            onBlur?.(e);
        },
        [onBlur, suggestions, autoSelect],
    );

    // gets place predictions from the Google Places API
    const fetchSuggestions = useCallback(
        async (value) => {
            if (value) {
                setIsLoading(true);
                try {
                    const response = await googlePlacesAutocomplete({
                        input: value,
                        sessionToken: sessionToken,
                    });

                    if (response && Array.isArray(response)) {
                        setSuggestions(
                            response.map((item) => ({
                                id: item.place_id,
                                text: item.description,
                            })),
                        );
                    }
                } catch (err) {
                    console.error(err);
                    setSuggestions([]);
                } finally {
                    setIsLoading(false);
                }
            }
        },
        [sessionToken],
    );

    // fetch new suggestions in every debounced value change
    useEffect(() => {
        if (inputValue && isNaN(inputValue) && inputValue.length >= AUTOCOMPLETE_MIN_CHARACTERS) {
            fetchSuggestions(inputValue);
        } else {
            setSuggestions([]);
        }
    }, [inputValue, fetchSuggestions]);

    return (
        <Typeahead
            ref={ref}
            {...props}
            name={inputNameTypeahead}
            label={label}
            variant={variant}
            buttonProps={
                buttonProps || enableGeolocation
                    ? {
                          iconComponent: geolocationIsLoading
                              ? Icon3Dots
                              : geolocationState?.isBrowserGeolocation
                                ? IconXCircle
                                : IconNavigation,
                          type: 'button',
                          disabled: isLoading,
                          onClick: handleGeolocationButton,
                          ariaLabel: 'Use Geolocation Services',
                      }
                    : null
            }
            className={classnames(
                className,
                geolocationIsLoading ? styles[`typeahead--geolocation-loading`] : '',
            )}
            items={suggestions}
            inputValue={inputValue}
            defaultInputValue={defaultInputValue}
            onInputChange={handleInputChange}
            onSelectionItemChange={onSelectionItemChange}
            isInvalid={isInvalid}
            validationErrors={validationErrors}
            onBlur={handleBlur}
        />
    );
});

export const PlacesTypeahead = forwardRef((props, ref) => {
    const { googleMaps } = props;
    if (!googleMaps.apiKey) {
        console.warn('Missing Google Maps apiKey');
        return null;
    }

    return (
        <GoogleMapsLoader apiKey={googleMaps.apiKey}>
            <GeolocationProvider>
                <PlacesAutocomplete {...props} ref={ref} />
            </GeolocationProvider>
        </GoogleMapsLoader>
    );
});
PlacesTypeahead.propTypes = {
    /**
     * Google Maps API key
     */
    googleMaps: PropTypes.object,
    /**
     * geolocation success callback
     */
    onGeolocationSuccess: PropTypes.func,
    /**
     * Enable geolocation buttons
     */
    enableGeolocation: PropTypes.bool,
    /**
     * Whether select the first place suggestion automatically on input blur and the callback function
     */
    autoSelect: PropTypes.shape({
        enabled: PropTypes.bool,
        callback: PropTypes.func,
    }),
};
