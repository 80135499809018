export const googleMapsGeocode = async (params) => {
    if (window.google?.maps?.Geocoder == null) {
        console.error('Google Maps API not loaded');
        return;
    }

    const geocodeApi = new window.google.maps.Geocoder();
    let response = null;

    try {
        response = await new Promise((resolve, reject) => {
            window.gm_authFailure = function (e) {
                window.gm_authFailure = undefined;
                reject();
            };

            const request = geocodeApi.geocode({
                componentRestrictions: { country: 'us' },
                ...params,
            });

            if (!request) {
                reject();
            } else {
                request.then(resolve).catch(reject);
            }
        });
    } catch (e) {
        console.error('Geocode API error');
    }

    return response;
};
