/**
 * Get city/state from a google map Place Details or Geocoder API response
 * @param {response} - A Place Details response object or Geocoder response result object
 * @returns {string} city, state
 */
export const getCityFromAddressComponents = (response) => {
    if (!response) {
        return;
    }

    const addressComponents = Array.isArray(response.results)
        ? response.results[0]?.address_components
        : response.address_components;

    const city = [
        addressComponents?.find((a) => a.types.includes('locality'))?.short_name,
        addressComponents.find((a) => a.types.includes('administrative_area_level_1'))?.short_name,
    ]
        .filter((item) => item)
        .join(', ');

    return city;
};

/**
 * Get zipcode from a google map Place Details or Geocoder API response
 * @param {response} - A Place Details response object or Geocoder response result object
 * @returns {number} zipcode
 */
export const getZipcodeFromAddressComponents = (response) => {
    if (!response) {
        return;
    }
    let zipCode = null;
    if (Array.isArray(response.results)) {
        for (const r of response.results) {
            const postalCode = r.address_components?.find((a) =>
                a.types.includes('postal_code'),
            )?.long_name;

            if (postalCode) {
                zipCode = postalCode;
                break;
            }
        }
    } else if (response.address_components) {
        zipCode = response.address_components?.find((a) =>
            a.types.includes('postal_code'),
        )?.long_name;
    }
    return zipCode;
};
